<template>
    <div class="container">
        <div v-if="showForm" class="row no-gutter">
            <div class="col p-0">
                <div class="claim-form mt-4" style="margin: 0 auto;">
                    <div class="claim-form__header">
                        <div>
                            <h2 class="claim-form__title">Request for License details</h2>
                            <p class="claim-form__subtitle claim-form__subtitle--light">In order to process your replacement bike hire, fill in the form and submit it.</p>
                        </div>

                        <div class="claim-form__icon">
                            <img :src="imgurl" alt=""/>
                        </div>
                    </div>

                    <div class="claim-form__body">
                        <FormulateForm
                                @submit="submitHandler"

                        >
                        <ul class="claim-form__ul" type="none">
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Your Details</h2>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                        type="text"
                                        name="claim-number"
                                        label="Claim Number"
                                        readonly
                                        v-model="data.claimNumber"/>
                            </li>
                            <li class="claim-form__section section">
                                <div class="row no-gutter">
                                    <div class="col col-md-6">
                                        <FormulateInput
                                                type="text"
                                                class="w-100"
                                                label="Your Name"
                                                name="owner-name"
                                                help="Full Name"
                                                readonly
                                                v-model="data.ownerName"/>
                                    </div>
                                </div>
                            </li>
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Your License Details</h2>
                                <p>Enter your details in this section</p>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                        type="text"
                                        class="w-100"
                                        validation="required"
                                        label="Drivers License number"
                                        name="license-number"
                                        v-model="data.license.number"/>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                        v-model="data.license.country"
                                        type="radio"
                                        label="Country License registered to"
                                        name="license-country"
                                        validation="required"
                                        :options="{ Australia: 'Australia', Another: 'Another Country' }"/>
                            </li>
                            <template v-if="australiaLicense">
                                <li class="claim-form__section section">
                                    <FormulateInput
                                            label="State in which the license was obtained"
                                            type="select"
                                            name="license-state"
                                            validation="required"
                                            :options="stateOptions"
                                            v-model="data.license.state"
                                    />
                                </li>
                            </template>

                            <template v-if="otherCountryLicense">
                                <li class="claim-form__section section">
                                    <FormulateInput
                                            type="text"
                                            label="Country in which the license was obtained"
                                            name="license-other"
                                            validation="required"
                                            v-model="data.license.otherCountryName"/>
                                </li>
                            </template>

                            <template v-if="australiaLicense || otherCountryLicense">
                                <li class="claim-form__section section">
                                    <FormulateInput
                                            v-model="files.licenseFront"
                                            type="image"
                                            class="w-100"
                                            name="license-front"
                                            label="Upload a copy or Click to take a photo of your license (Front)"
                                            help="Take a photo of your license from the Front only"
                                            validation="mime:image/jpeg,image/png,image/gif"/>
                                </li>
                                <li class="claim-form__section section">
                                    <FormulateInput
                                            v-model="files.licenseBack"
                                            type="image"
                                            class="w-100"
                                            name="license-back"
                                            label="Upload a copy or Click to take a photo of your license (Back)"
                                            help="Take a photo of your license from the Back only"
                                            validation="mime:image/jpeg,image/png,image/gif"/>
                                </li>
                            </template>

                            <template v-if="otherCountryLicense">
                                <li class="claim-form__section section">
                                    <FormulateInput
                                            v-model="files.passportPhoto"
                                            type="image"
                                            class="w-100"
                                            name="passport"
                                            label="Upload a copy of your Passport photo"
                                            help="Take a photo of your Passport with your picture displayed"
                                            validation="mime:image/jpeg,image/png,image/gif"/>
                                </li>
                                <li class="claim-form__section section">
                                    <FormulateInput
                                            v-model="files.passportAddress"
                                            type="image"
                                            class="w-100"
                                            name="resident-doc"
                                            label="Upload a copy of a document with your address"
                                            help="Provide a photo of a Phone, Electricity, Water bill or Bank statement with you residential address"
                                            validation="mime:image/jpeg,image/png,image/gif"/>
                                </li>
                            </template>
                            <li class="claim-form__section section d-flex justify-content-center">
                                <div>
                                    <button @click="sendDetails" class="submit" style="padding: 12px 16px;margin-top: 16px;color: white;">
<!--                                    <button type="submit" class="submit" style="padding: 12px 16px;margin-top: 16px;color: white;">-->
                                        <div v-if="isSending" class="spinner"></div>
                                        <span v-if="!isSending">Submit</span></button>
                                    <div class="sr-legal-text">
                                    </div>
                                </div>
                            </li>
                        </ul>
                        </FormulateForm>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSuccess" style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);">
            <div class="success-form">
                <div>Success<br></div>
                <div><br></div>
                <div>Thank you&nbsp;{{data.ownerName}} for submitting your license details. <br></div>
                <div><br></div>
                <div><br></div>
                <div>Your claim reference "{{data.claimNumber}}" will now progress further in the process.<br></div>
                <div><br></div>
                <div><br></div>
                <p>Regards,<br></p>
                <p>The Your Right to Ride Team<br></p>
                <p style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 8pt; margin-left: 0cm">1300 507 933<br></p>
                <p style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 8pt; margin-left: 0cm">
                    <a style="color: rgb(5, 99, 193);" href="http://www.yourrighttiride.com.au/">www.yourrighttiride.com.au</a></p>
                <p style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 8pt; margin-left: 0cm">
                    <a style="color: rgb(5, 99, 193);" href="mailto:info@yourrighttoride.com.au">info@yourrighttoride.com.au</a></p>
            </div>
        </div>
        <div v-if="showError" style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);">
            <h2 style="text-align: center;">FORM NOT FOUND</h2>
            <h4 style="text-align: center; color: #444;"><a href="http://yourrighttoride.com.au/" style="color: #444;">Go to main page</a></h4>
        </div>
    </div>

</template>

<script>
    import Axios from 'axios';
    import {mapGetters} from "vuex";

    export default {
        name: 'request-license',
        data() {
            return {
                isSpinner: false,
                isSending: false,
                showForm: false,
                showSuccess: false,
                showError: false,
                hash: '',
                files: {
                    licenseFront: null,
                    licenseBack: null,
                    passportPhoto: null,
                    passportAddress: null,
                },
                data: {
                    test: null,
                    claimNumber: '',
                    ownerName: '',
                    license: {
                        number: '',
                        country: '',
                        otherCountryName: '', // if other country selected
                        state: '',
                    }
                },
                imgurl: 'https://dev.pay.yourrighttoride.com.au/img/logo.png',
            };
        },
        computed: {
            ...mapGetters({
                stateOptions: 'addressStates'
            }),
            australiaLicense() {
                return this.data.license.country == 'Australia';
            },
            otherCountryLicense() {
                return this.data.license.country == 'Another';
            },
        },
        created() {
            this.hash = this.$route.params.code;
        },
        mounted() {
            this.loadClaimData();
        },

        methods: {
            async submitHandler (data) {
               console.log('dddata',data)
                let test= this.files.licenseFront.files[0]
                let f2 = test.file;
              // this.handleFileSelect(f2);
               console.log('iii',test.previewData)
            },
            //handleFileSelect(f) {
            //    //console.log('evt',evt);
            //    //
            //    //if (!evt){
            //    //    return;
            //    //}
            //    //var f = evt.target.files[0]; // FileList object
            //    console.log('f',f);
            //    //var f= file;
            //    var reader = new FileReader();
            //    // Closure to capture the file information.
            //    reader.onload = (function (theFile) {
            //        return function (e) {
            //            var binaryData = e.target.result;
            //            //Converting Binary Data to base 64
            //            var base64String = window.btoa(binaryData);
            //            console.log('base64String',base64String);
            //            //showing file converted to base64
            //            return base64String;
            //        };
            //    })(f);
            //    // Read in the image file as a data URL.s
            //    return reader.readAsText(f);
            //},
            sendDetails() {
                if (this.isSending) {
                    return;
                }
                this.isSpinner = true;
                this.isSending = true;
                let data = {
                    claimCode: this.hash,
                    licenseNumber: this.data.license.number,
                    country: this.data.license.country,
                    state: this.data.license.state,
                    anotherCountry: this.data.license.otherCountryName,
                };

                let fd = new FormData();
                fd.append('data', JSON.stringify(data));
                fd.append('license_photo_front', this.files.licenseFront && this.files.licenseFront.input.files ? this.files.licenseFront.input.files[0] : null);
                fd.append('license_photo_back', this.files.licenseBack && this.files.licenseBack.input.files ? this.files.licenseBack.input.files[0] : null);
                fd.append('passport_photo', this.files.passportPhoto && this.files.passportPhoto.input.files ? this.files.passportPhoto.input.files[0] : null);
                fd.append('passport_address_photo', this.files.passportAddress && this.files.passportAddress.input.files ? this.files.passportAddress.input.files[0] : null);

                let licensePhotoFront = (this.files.licenseFront && this.files.licenseFront.files && this.files.licenseFront.files[0]) ? this.files.licenseFront.files[0].previewData : null;
                let licensePhotoBack = (this.files.licenseBack && this.files.licenseBack.files && this.files.licenseBack.files[0]) ? this.files.licenseBack.files[0].previewData : null;
                let passportPhoto = (this.files.passportPhoto && this.files.passportPhoto.files && this.files.passportPhoto.files[0]) ? this.files.passportPhoto.files[0].previewData : null;
                let passportAddress = (this.files.passportAddress && this.files.passportAddress.files && this.files.passportAddress.files[0]) ? this.files.passportAddress.files[0].previewData : null;
                let newFD = {
                    data: data,
                    license_photo_front: licensePhotoFront,
                    license_photo_back: licensePhotoBack,
                    passport_photo: passportPhoto,
                    passport_address_photo: passportAddress,
                };

                //{headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/json',}}

                Axios.post('/efr/license-form-data', newFD )
                    .then(res => {
                        if (res && res.data && res.data._status) {
                            this.$toast.success('SAVED!');
                                this.showForm = false;
                              this.showSuccess = true;
                        } else if (res.data._errors) {
                            res.data._errors.forEach(e => this.$toast.error(e));
                        }
                    })
                    .catch(e => console.warn(e))
                    .finally(e => {
                        this.isSending = false;
                    });

            },
            loadClaimData() {
                Axios.get('/efr/license-form-data/' + this.hash)
                    .then(res => {
                        if (res && res.data && res.data._status) {
                            this.data.claimNumber = res.data.data.claimNumber;
                            this.data.ownerName = res.data.data.ownerName;
                            //this.data.lastName = res.data.data.lastName;
                            //this.data.fullName = this.data.firstName + ' ' + this.data.lastName;
                            this.showForm = true;
                            this.showError = false;
                        } else {
                            this.showForm = false;
                            this.showError = true;
                        }
                    })
                    .catch(e => {
                        console.warn(e);
                        this.showForm = false;
                        this.showError = true;
                    });
            }
        },
    };
</script>
<style scoped>
    .success-form {
        font-size: 21px;
        font-weight: 500;
    }

    .submit {
        background: var(--accent-color);
        border-radius: var(--radius);
        color: white;
        border: 0;
        padding: 12px 16px;
        margin-top: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
        display: block;
        min-width: 140px;
    }

    .spinner,
    .spinner:before,
    .spinner:after {
        border-radius: 50%;
    }

    .spinner {
        color: #FFFFFF;
        font-size: 22px;
        text-indent: -99999px;
        margin: 0px auto;
        position: relative;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
        position: absolute;
        content: "";
    }

    .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: var(--accent-color);
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: var(--accent-color);
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
    }

    @-webkit-keyframes loading {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes loading {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

</style>